export enum Event {
  onPagination = 'onPagination',
  onOrder = 'onOrder',
  onGlobalSearch = 'onGlobalSearch',
  onSearch = 'onSearch',
  onClick = 'onClick',
  onDoubleClick = 'onDoubleClick',
  onCheckboxSelect = 'onCheckboxSelect',
  onSelectAll = 'onSelectAll',
  onColumnResizeMouseDown = 'onColumnResizeMouseDown',
  onColumnResizeMouseUp = 'onColumnResizeMouseUp',
  onRowDrag = 'onRowDrag',
  onRowDrop = 'onRowDrop',
  onRowCollapsedShow = 'onRowCollapsedShow',
  onRowCollapsedHide = 'onRowCollapsedHide',
  onRowContextMenu = 'onRowContextMenu',
}
